import React from "react"
import config from "../config.yaml"

export default class Footer extends React.Component {
    render() {
        return (
            <>
            <footer className="footer-mainpage">
                <div>
                    <a href={config.linkSource} target="_blank" rel="noopener noreferrer">
                        Website source
                    </a>
                </div>
            </footer>
            </>
        )
    }
}