import React from "react"
import { Link } from "gatsby"

const NavBarItem = ({ text, link }) => (
  <li className="navbar-item">
    <Link to={link} className="navbar-link">{text}</Link>
  </li>
)

const NavBar = ({ navLinks }) => (
  <>
    <nav className="navbar">
      <ul className="navbar-menu">
        {navLinks.map(nl => <NavBarItem {...nl} key={nl.link} />)}
      </ul>
    </nav>
    <style jsx>{``}</style>
  </>
);

export default NavBar;
